
import CloudFun, { Condition, defineComponent, Operator, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUploader,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      id: 'store',
      title: '店家',
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "店家清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "店家清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, resizable: false },
        { field: "StoreCategory.Name", title: "營業類別", showHeaderOverflow: true, showOverflow: true },
        {
          field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: '包含' }],
          slots: { filter: 'filter-name' }
        },
        { field: "IsPhysical", title: "實體店面", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => cellValue ? "是" : "否" },
        { field: "Merchant.Name", title: "商家", showHeaderOverflow: true, showOverflow: true },
        { field: "Contract.Number", title: "契約", showHeaderOverflow: true, showOverflow: true },
        { field: "ContactPerson", title: "聯絡人", showHeaderOverflow: true, showOverflow: true },
        { field: "ContactPhone", title: "連絡電話", showHeaderOverflow: true, showOverflow: true },
      ],
      promises: {
        query: model ? (params) => model.dispatch("store/query", params) : undefined,
        queryAll: model ? () => model.dispatch("store/query") : undefined,
        save: model ? (params) => model.dispatch("store/save", params) : undefined,
      },
      modalConfig: { width: 600, showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: "right",
      span: 3,
      items: [
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { placeholder: '系統自動產生', disabled: true } } },
        { field: 'ContractId', title: '契約', span: 12, slots: { default: 'column-contract-id' } },
        { field: 'Name', title: '名稱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入文字' } } },
        { field: 'StoreCategoryId', title: '營業類別', span: 12, slots: { default: 'column-store-category-id' } },
        { field: 'Email', title: '服務信箱', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入Email' } } },
        { field: 'IsPhysical', title: '實體店面', span: 12, itemRender: { name: '$select', props: { placeholder: '請選擇', options: [{ label: '是', value: true }, { label: '否', value: false }] } } },
        { field: 'Phone', title: '門市電話', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
        { field: 'Address.Line', title: '地址', span: 12, itemRender: { name: '$input', props: { placeholder: '請輸入地址' } } },
        {
          span: 12,
          children: [
            { field: 'ContactPerson', title: '聯絡人', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入姓名' } } },
            { field: 'ContactPhone', title: '聯絡電話', span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入電話號碼' } } },
            { field: 'Latitude', title: '緯度', span: 24, itemRender: { name: '$input', props: { type: 'float', placeholder: '請選擇', digits: 6, min: -90, max: 90 } } },
            { field: 'Longitude', title: '經度', span: 24, itemRender: { name: '$input', props: { type: 'float', placeholder: '請選擇', digits: 6, min: -180, max: 180 } } },
          ]
        },
        { field: 'Picture.Uri', title: '圖片', span: 12, slots: { default: 'column-picture' } },
      ],
      rules: {
        ContractId: [{ required: true }],
        StoreCategoryId: [{ required: true }],
        Name: [{ required: true }],
        Phone: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        ContactPerson: [{ required: true }],
        ContactPhone: [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error('聯絡電話為必填');
            return CloudFun.utils.validator.validatePhoneNumber(params.itemValue, 'TW');
          }
        }],
        Email: [{
          validator: (params) => {
            if (!params.itemValue) return;
            return CloudFun.utils.validator.validateEmail(params.itemValue);
          }
        }],
        'Picture.Uri': [{
          required: true,
          validator: (params) => {
            if (!params.itemValue) return new Error('未提供店家圖片');
            if (params.itemValue && typeof params.itemValue === 'string' && !params.itemValue.startsWith('http')) return new Error('圖檔尚未上傳完畢');
          }
        }]
      },
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇契約",
      textField: "Number",
      valueField: "Id",
      dropdownWidth: 450,
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Merchant.Name", title: "商家", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Type", title: "類型", showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.ContractType).find(e => e.Value === cellValue)?.Name : undefined },
      ],
      multiselect: false,
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("contract/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("contract/query", params), // eslint-disable-line
      },
    };

    const storeCategorySelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇營業類別",
      textField: "Name",
      valueField: "Id",
      dropdownWidth: 450,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true, width: "100%" },
      ],
      multiselect: false,
      pagerConfig: { pageSize: 6, autoHidden: true },
      promises: {
        find: (value) => model!.dispatch("serviceCategory/find", value), // eslint-disable-line
        query: (params) => {
          params.condition = new Condition("MerchantId", Operator.IsNull).and("ParentId", Operator.IsNull);
          return model!.dispatch("serviceCategory/query", params); // eslint-disable-line
        },
      },
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      storeCategorySelectorOptions,
      uploadAction: `${process.env.VUE_APP_STORAGE_SERVICE}/api/files`,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
  },
});
